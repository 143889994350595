import { render, staticRenderFns } from "./TabelaTrimestre.vue?vue&type=template&id=c033ce0e&scoped=true"
import script from "./TabelaTrimestre.vue?vue&type=script&lang=js"
export * from "./TabelaTrimestre.vue?vue&type=script&lang=js"
import style0 from "./TabelaTrimestre.vue?vue&type=style&index=0&id=c033ce0e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c033ce0e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCard,VSimpleTable})
