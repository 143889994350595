<template>
    <v-form ref="form">
        <div class="d-flex">
            <div class="mr-4 flex-grow-1">
                <v-combobox
                    :placeholder="placeholder"
                    v-bind:label="label"
                    :class="{
                        select: true,
                        
                        active: menu1,
                    }"
                    v-bind:required="required"
                    v-bind:disabled="disabled"
                    :menu-props="menuProps"
                    v-model="cmpValue"
                    outlined
                    validate-on-blur
                    dense
                    multiple
                    deletable-chips
                    :delimiters="[',']"
                    small-chips
                    clearable
                    @change="delimit"
                    :rules="[required ? produtoRegra : true]"
                ></v-combobox>
            </div>
            <div class="flex-shrink-0">
                <v-btn
                    color="primary"
                    class="new-button w-100"
                    @click="abrirImportacao"
                >
                    Importar prod
                </v-btn>
            </div>
        </div>
        <v-dialog
            content-class="br-15"
            scrollable
            v-model="dialogoProduto"
            width="600"
        >
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title>Importação de produtos </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogoProduto = false"
                    >
                        <v-icon size="20px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-5">
                    Selecione o arquivo para importação. Apenas a coluna produto
                    será importada.
                    <v-file-input
                        accept=".csv"
                        label="Arquivo"
                        dense
                        v-model="arquivoProduto"
                        outlined
                        class="mt-5 input-cinza"
                    ></v-file-input>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="new-button mr-3 px-4 mb-4"
                        @click="processaCSV"
                    >
                        <v-icon class="mr-3">mdi-check-circle</v-icon>
                        Confirmar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
</template>
 
 <script>
export default {
    name: 'PordutoInput',
    model: { prop: 'value', event: 'input' },

    props: [
        'value',
        'rules',
        'min',
        'max',
        'placeholder',
        'label',
        'required',
        'disabled',
        'change',
        'prepend-icon',
        'firstDayOfWeek',
        'produtosServidor',
        'checkProduto'
        
    ],
    data() {
        return {
            mes: null,
            menu1: false,
            dialogoProduto: false,
            arquivoProduto: false,

            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: 'select-outline',
                transition: 'slide-y-transition',
            },
            produtoRegra (value)  {
                // console.log('O produto é obrigatório')
                // if (this.required) {
                    // console.log('testando...produto',value.length)
                    if (value instanceof Array && value.length == 0) {
                        return 'O produto é obrigatório';
                    }
                    return !!value || 'O produto é obrigatório';
                // } 
                // return true
            },
        }
    },
    requerido() {
        return this.required
    },
    computed: {
        cmpValue: {
            get: function () {
                return this.value
            },
            set: function (newValue) {
                this.$emit('input', newValue)
            },
        },
        
    },
    methods: {
        desvalidar() {
            this.$refs.form.resetValidation()
        },
        validar() {
            // console.log('passou aqui',this.required)
            return this.$refs.form.validate() 
        },
        abrirImportacao() {
            this.arquivoProduto = null
            this.dialogoProduto = true
        },
        async processaCSV() {
            const parseCsv = async (csv) => {
                return new Promise((resolve) => {
                    this.$papa.parse(csv, {
                        delimiter: ';',
                        header: true,
                        dynamicTyping: true,
                        skipEmptyLines: true,
                        complete: function (results) {
                            resolve(results)
                        },
                    })
                })
            }

            var csv = await parseCsv(this.arquivoProduto)

            if (csv.errors.length > 0) {
                this.$dialog.notify.error('Erro ao importar arquivo', {
                    position: 'top-right',
                    timeout: 5000,
                })
                return
            }
            if (!csv.data[0].produto) {
                this.$dialog.notify.error('Coluna produto não encontrada', {
                    position: 'top-right',
                    timeout: 5000,
                })
                return
            }
            var candidate = csv.data.map((e) => e.produto)
            // console.log('this.filtroProdutos',this.filtroProdutos)
            if (this.required) {
                this.cmpValue = candidate.filter(item => {
                    // console.log(item)
                    let encontrado = false
                    for (const produto of this.produtosServidor) {
                        if (produto.codigo == item) {
                            console.log(produto.codigo, item)
                            encontrado = true
                        }
                    }
                    return encontrado
                }).map(item => {
                    return item + ''
                })
                console.log(this.cmpValue )
            } else {
                this.cmpValue = candidate
                console.log(this.cmpValue )
            }
            this.dialogoProduto = false
        },
        delimit(v) {
            const reducer = (a, e) => [...a, ...e.split(/[,]+/)]
            var candidate = [...new Set(v.reduce(reducer, []))]
            console.log({
                required:this.required,
                candidate,
                cmpValue: this.cmpValue,
                produtosServidor: this.produtosServidor
            })
            // console.log('this.filtroProdutos',this.filtroProdutos)
            if (this.required || this.checkProduto) {
                this.cmpValue = candidate.filter(item => {
                    let encontrado = false
                    for (const produto of this.produtosServidor) {
                        if (produto.codigo == item) {
                            console.log(produto.codigo, item)
                            encontrado = true
                        }
                    }
                    return encontrado
                })
                console.log(this.cmpValue )
            }

        },
    },
}
</script>
 
 <style>
.input-cinza.active .v-input__slot {
    border-bottom: none;
}

.input-cinza.active fieldset legend {
    width: 0 !important;
}

.input-mes .v-date-picker-header__value button {
    font-weight: 500;
    color: var(--v-primary-base) !important;
}

.input-mes,
.input-mes .v-picker__body {
    width: 100% !important;
}
.input-mes .v-btn__content {
    text-transform: capitalize;
}
.input-mes input {
    text-transform: uppercase !important;
}
.input-mes .theme--light.v-date-picker-table th {
    color: #ccc !important;
}
.input-mes .v-date-picker-table--date td {
    color: #ccc !important;
    padding: 0 !important;
}
</style>